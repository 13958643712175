import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import * as urls from 'src/app/common/end-points/urls'
import { MatDialog } from '@angular/material/dialog';
import { InsufficientPopupComponent } from 'src/app/common/dialog-popup/insufficient-popup/insufficient-popup.component';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  pageEvent: Subject<any> = new Subject();
  dashboardContent: boolean = false;
  jobpagecontent: boolean = false;
  findcandidateContent: boolean = false;
  candidateProfile: boolean = false;
  postJobPage: boolean = false;
  pricingPage: boolean = false;
  transactionPage: boolean = false;
  jobdetailpage: boolean = false;
  campaignpage: boolean = false;
  offerCheckPage: boolean = false;
  isTopBarHided = false;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    public auth: AuthService,) { }


  //api call to get jobpostcount
  jobpostcount(type: any): Observable<any> {
    return this.http.get<any>(urls.vendor.jobpostcount + "/" + type)
  }

  //api call to get interviewcount
  interviewcount(type: any): Observable<any> {
    return this.http.get<any>(urls.vendor.interviewcount + "/" + type)
  }

  //api call to get promocount
  promocount(type: any): Observable<any> {
    return this.http.get<any>(urls.vendor.promocount + "/" + type)
  }

  //api call to get hiredcount
  hiredcount(type: any): Observable<any> {
    return this.http.get<any>(urls.vendor.hiredcount + "/" + type)
  }


  recPages(val: any) {
    // this.pageEvent.next(val); 

    if (val == "dashboardpage") {
      this.dashboardContent = true;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false;
      this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "joblistpage") {
      this.dashboardContent = false;
      this.jobpagecontent = true;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false;
      this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "campaignpage") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false;
      this.campaignpage = true;
      this.offerCheckPage = false;
    }
    else if (val == "candidateSearch") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = true;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "candidateProfile") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = true;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = false;

    }
    else if (val == "postJobPage") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = true;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "pricingPage") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = true;
      this.transactionPage = false;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "transactionPage") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = true;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "jobdetailpage") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = true; this.campaignpage = false;
      this.offerCheckPage = false;
    }
    else if (val == "offerChecks") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = true;
    }
    else if (val == "withoutTitle") {
      this.dashboardContent = false;
      this.jobpagecontent = false;
      this.findcandidateContent = false;
      this.candidateProfile = false;
      this.postJobPage = false;
      this.pricingPage = false;
      this.transactionPage = false;
      this.jobdetailpage = false; this.campaignpage = false;
      this.offerCheckPage = false;
    }

  }

  openInsufficientBox() {
    const dialogRef = this.dialog.open(InsufficientPopupComponent, {
      // panelClass: 'medium-dialog',
      // maxWidth: '400px',
      panelClass: 'reason-dialog',
      width: '58rem'
    });
  }

}
