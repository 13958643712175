import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginGuard } from './common/guard/login/login.guard';
import { SharedModule } from './common/module/shared-module';
import { ErrorInterceptor } from './interceptors/error/error.interceptor';
import { GlobalHttpInterceptor } from './interceptors/http/global-http.interceptor';
import { SpinnerInterceptor } from './interceptors/spinner/spinner.interceptor';
import { PanelService } from './services/pannel/panel.service';
import { MoreFiltersComponent } from './common/dialog-popup/more-filters/more-filters.component';
import { AddProfileComponent } from './common/dialog-popup/add-profile/add-profile.component';
import { BulkUploadComponent } from './common/dialog-popup/add-profile/bulk-upload/bulk-upload.component';
import { FileDragNDropDirective } from 'src/app/common/dialog-popup/add-profile/bulk-upload/file-drag-n-drop.directive';
import { PipelineComponent } from './common/dialog-popup/pipeline/pipeline.component';
//  import { NgxShimmerLoadingModule } from  'ngx-shimmer-loading';
// import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RecruiterRegisterIframeComponent } from './common/components/recruiter-register-iframe/recruiter-register-iframe.component';

@NgModule({
  declarations: [AppComponent, MoreFiltersComponent, AddProfileComponent, BulkUploadComponent, FileDragNDropDirective, PipelineComponent, RecruiterRegisterIframeComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    // NgxShimmerLoadingModule,
    NgxSkeletonLoaderModule.forRoot()
  ],
  providers: [
    LoginGuard,
    PanelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
