import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SwiperModule } from 'swiper/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import * as FilepondPluginImageEdit from 'filepond-plugin-image-edit';
import * as FilepondPluginImagePreview from 'filepond-plugin-image-preview';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { PanelService } from 'src/app/services/pannel/panel.service';
import { LeftSideNavService } from 'src/app/services/left-side-nav/left-side-nav.service';
import { MaterialModule } from '../material/material.module';
import { NgxUiLoaderConfig, NgxUiLoaderModule, SPINNER } from 'ngx-ui-loader';
import { registerPlugin } from 'filepond';
import { BarRatingModule } from 'ngx-bar-rating';
import { NgxEditorModule } from 'ngx-editor';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { FilePondModule } from 'ngx-filepond';
import {NgxDocViewerModule} from 'ngx-doc-viewer'

import { environment } from 'src/environments/environment';

import { FileUploadModule } from '@iplab/ngx-file-upload';
import { LottieModule } from 'ngx-lottie';
import { OtpModule } from 'nitro-otp';
import { NgOtpInputModule } from 'ng-otp-input';
import { AngularFireModule } from '@angular/fire';
import { ChartsModule } from 'ng2-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxShimmerLoadingModule } from  'ngx-shimmer-loading';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgxPaginationModule } from 'ngx-pagination';
import { LeaderboardCircleProgressComponent } from 'src/app/common/components/leaderboard-circle-progress/leaderboard-circle-progress.component';
import { ReadMoreComponent } from 'src/app/common/components/read-more/read-more.component';
import { V2JobGoalComponent } from 'src/app/common/dialog-popup/v2-job-goal/v2-job-goal.component';
import { FooterComponent } from '../components/footer/footer.component';

export function playerFactory() {
  // add this line
  return import('lottie-web'); // add this line
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsType: SPINNER.wanderingCubes,
  fgsPosition: 'center-center',
  fgsSize: 100,
  fgsColor: '#00b3ec',
  fastFadeOut: true,
  // logoUrl:'assets/logo_mainS.svg',
  // bgsColor: '#00b3ec',
  // pbColor: '#00b3ec',
  // bgsSize: 64,
};

registerPlugin(
  FilePondPluginFileValidateType,
  FilepondPluginImageEdit,
  FilepondPluginImagePreview
);

@NgModule({
  declarations: [LeaderboardCircleProgressComponent, ReadMoreComponent, V2JobGoalComponent, FooterComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MaterialModule,
    FlexLayoutModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
      tapToDismiss: true,
    }),
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SwiperModule,
    FileUploadModule,
    CommonModule,
    MaterialModule,
    SwiperModule,
    FilePondModule,
    NgxEditorModule,
    FormsModule,
    ReactiveFormsModule,
    BarRatingModule,
    LottieModule.forRoot({ player: playerFactory }), // add this line
    OtpModule,
    NgOtpInputModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    FileUploadModule,
    ChartsModule,
    NgCircleProgressModule.forRoot({
      outerStrokeWidth: 16,
      responsive: true,
      showSubtitle: false,
      showInnerStroke: false,
      animationDuration: 300,
      titleFontSize: '12',
      subtitle: [''],
      clockwise: false,
    }),
    ScrollingModule,
    NgxDocViewerModule,
    NgxShimmerLoadingModule,
    NgxSkeletonLoaderModule,
    InfiniteScrollModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgxPaginationModule

  ],
  exports: [
    CommonModule,
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BsDatepickerModule,
    ToastrModule,
    FilePondModule,
    HttpClientModule,
    NgxUiLoaderModule,
    BarRatingModule,
    LottieModule,
    SwiperModule,
    NgxEditorModule,
    OtpModule,
    NgOtpInputModule,
    AngularFireModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    FileUploadModule,
    ChartsModule,
    NgCircleProgressModule,
    ScrollingModule,
    NgxDocViewerModule,
    NgxShimmerLoadingModule,
    NgxSkeletonLoaderModule,
    InfiniteScrollModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgxPaginationModule,
    LeaderboardCircleProgressComponent,
    ReadMoreComponent,
    FooterComponent
  ],
  providers: [DatePipe, PanelService, LeftSideNavService],
})
export class SharedModule {}
