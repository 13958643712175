import { JobsService } from 'src/app/services/jobs/jobs.service';
import { environment } from 'src/environments/environment';

//const basePath = environment.baseUrl;

const v1ApiBasePath = environment.baseUrl + '/api/v1';
const v2ApiBasePath = environment.baseUrl + '/api/v2';
const v3ApiBasePath = environment.baseUrl + '/api/v3';

const hrBasePath = environment.hrBasePath;
const jobsBasePath = environment.jobsBasePath;
const jobsAtsBasePath = environment.jobsAtsBasePath;
const masterBasePath = environment.masterBasePath;
const chatBasePath = environment.chatBasePath;
const offersBasePath = environment.offersBasePath;
const subsBasePath = environment.subsBasePath;
const vendorNotifyBasePath = environment.vendorNotifyBasePath;
const candBasePath = environment.candBasePath;
const profileBasePath = environment.profileBasePath;
const candAuthBasePath = environment.candAuthBasePath;
const vendorAuthBasePath = environment.vendorAuthBasePath;
const devAtsBasePath = environment.devAtsBasePath;

//For prd release
//const devAtsBasePath = environment.jobsAtsBasePath;

// above code commented and below code by dp 

/* const v1ApiBasePath = environment.baseUrl + '/api/v1';
const v2ApiBasePath = environment.baseUrl + '/api/v2';
const v3ApiBasePath = environment.baseUrl + '/api/v3';

const hrBasePath = 'https://api-vendor-znpc27vwgq-el.a.run.app';
const jobsBasePath = 'https://api-jobs-znpc27vwgq-el.a.run.app';
const jobsAtsBasePath = 'https://api-job-ats-znpc27vwgq-el.a.run.app';
const masterBasePath = 'https://api-master-znpc27vwgq-el.a.run.app';
const chatBasePath = 'https://api-chat-znpc27vwgq-el.a.run.app';
const offersBasePath = 'https://api-offer-check-znpc27vwgq-el.a.run.app';
const subsBasePath = 'https://api-payment-znpc27vwgq-el.a.run.app';
const vendorNotifyBasePath = 'https://api-vendor-notifications-znpc27vwgq-el.a.run.app';
const candBasePath = 'https://api-candidate-znpc27vwgq-el.a.run.app';
const profileBasePath = 'https://api-dbsearch-znpc27vwgq-el.a.run.app';
const candAuthBasePath = 'https://api-candidate-auth-znpc27vwgq-el.a.run.app'; 
const vendorAuthBasePath = 'https://api-vendor-auth-znpc27vwgq-el.a.run.app'; */



/* const v1ApiBasePath = environment.baseUrl + '/api/v1';
const v2ApiBasePath = environment.baseUrl + '/api/v2';
const v3ApiBasePath = environment.baseUrl + '/api/v3';

const hrBasePath = 'https://uat-ms-vendor-znpc27vwgq-el.a.run.app/hr';
const jobsBasePath = 'https://uat-ms-jobs-znpc27vwgq-el.a.run.app/jobs-api';
const jobsAtsBasePath = 'https://uat-ms-job-ats-znpc27vwgq-el.a.run.app/jobATS';
const masterBasePath = 'https://uat-ms-master-znpc27vwgq-el.a.run.app/master';
const chatBasePath = 'https://uat-ms-chat-znpc27vwgq-el.a.run.app/chat';
const offersBasePath = 'https://uat-ms-offer-check-znpc27vwgq-el.a.run.app/offers';
const subsBasePath = 'https://uat-ms-payment-znpc27vwgq-el.a.run.app/subscription';
const vendorNotifyBasePath = 'https://uat-ms-vendor-notifications-znpc27vwgq-el.a.run.app/vendorNotifications';
const candBasePath = 'https://candidatems-znpc27vwgq-el.a.run.app/candidate';
const profileBasePath = 'https://uat-ms-dbsearch-znpc27vwgq-el.a.run.app/profileSearch';
const candAuthBasePath = 'https://dev-candidate-auth-ms-znpc27vwgq-el.a.run.app/candidateAuth';
const vendorAuthBasePath = 'https://uat-ms-vendor-auth-znpc27vwgq-el.a.run.app/vendorAuth'; */

// tslint:disable-next-line: one-variable-per-declaration
export const BasePath = environment.baseUrl,

  auth = {
    signUp: `${vendorAuthBasePath}/v1/userAuth/signup`,
    signUpAnotherUser: `${vendorAuthBasePath}/v1/userAuth/signup`,
    login: `${vendorAuthBasePath}/v1/userAuth/login`,
    mobilelogin: `${candAuthBasePath}/v2/userAuth/mobilelogin`,
    v1Mobilelogin: `${candAuthBasePath}/v1/userAuth/mobilelogin`,
    mobilesignup: `${candAuthBasePath}/v2/userAuth/mobilesignup`,
    candidatelogin: `${candAuthBasePath}/v1/userAuth/login`,
    locations: `${masterBasePath}/v1/locations`,
    refreshTokens: `${vendorAuthBasePath}/v1/userAuth/refreshtoken`,
    forgotPassword: `${hrBasePath}/v1/vendoruser/forgotpassword`,
    changePassword: `${hrBasePath}/v1/vendoruser/changepassword`,
    resetWithOtp: `${hrBasePath}/v1/vendoruser/resetpassword`,
    allSkills: `${masterBasePath}/v1/skills`,
    s3Image: `${masterBasePath}/v1/file/url/candidate`,
    organizationSearch: `${hrBasePath}/v1/vendor/search`,
    passToken: `${vendorAuthBasePath}/v1/userAuth/getpasstoken`,
    coinsPlan: `${subsBasePath}/v1/vendor/coin-plans`,
    ledgerBalance: `${subsBasePath}/v1/vendor/ledger-balance`,
    refreshTokens2: `${candAuthBasePath}/v1/userAuth/refreshtoken`,
    candidateforgotPassword: `${candAuthBasePath}/v1/userAuth/forgotpassword`,
    checkUser: `${hrBasePath}/v1/vendor/checkemail`,
    candidateResetWithOtp: `${candAuthBasePath}/v1/userAuth/resetpassword`,
    candidatePasstoken: `${candAuthBasePath}/v1/userAuth/getpasstoken`,
    suggestions: `${jobsBasePath}/v1/candidate/search/suggestions`,
    anySuggestions: `${profileBasePath}/v1/vendor/suggestions`,
    logos: `${masterBasePath}/v1/master/scrollimages`,
    getOtp: `${candAuthBasePath}/v2/userAuth/get-otp`,
    verifyOtp: `${candAuthBasePath}/v2/userAuth/verify-otp`,
    resendOtp: `${candAuthBasePath}/v2/userAuth/resend-otp`,
    googleSignIn: `${candAuthBasePath}/v2/userAuth/googlesignup`,
    contact: `${masterBasePath}/v1/contact/`,
    v2Contact: `${masterBasePath}/v2/contact/`,
    deleteVendor: `${hrBasePath}/v1/vendoruser/delete`,
    errorAPI: `${masterBasePath}/v1/master/api-error`,
    //forgotPassword: `${v1ApiBasePath}/vendor/forgotpassword`,
    //changePassword: `${v1ApiBasePath}/vendor/changepassword`,
    //resetWithOtp: `${v1ApiBasePath}/vendor/resetpassword`,
    //passToken: `${v1ApiBasePath}/vendor/getpasstoken`,
    //googleSignIn: `${v1ApiBasePath}/candidate/user/googlesignup`,
    //allSkills: `${v1ApiBasePath}/details/skills`,
    subscriptionPlans: `${v1ApiBasePath}/details/subscriptionplans`,
    planActive: `${v1ApiBasePath}/vendor/subscriptions/active`,
    planActive2: `${v2ApiBasePath}/vendor/subscriptions/active`,
    trialPlan: `${v1ApiBasePath}/vendor/subscriptions/default	`,
    companyList: `${hrBasePath}/v1/guest/list	`,
    acceptInvitation: `${vendorAuthBasePath}/v1/vendor/user-management/accept-invitation`
  },

  profile = {
    updateData: `${hrBasePath}/v1/vendor/update`,
    getData: `${hrBasePath}/v1/vendoruser/`,
    walletBalance: `${subsBasePath}/v1/vendor/payment/balance`,
    // getData: `${v1ApiBasePath}/details/vendors`,
    //updateData: `${v1ApiBasePath}/vendor/update`,
  },

  candidateProfile = {
    editProfile: `${candBasePath}/v1/update`,
    getData: `${candBasePath}/v1/profile`,
    postData: `${candBasePath}/v1/update`,
    education: `${candBasePath}/v1/education/`,
    postEducation: `${candBasePath}/v1/education/`,
    updateEducation: `${candBasePath}/v1/education/`,
    experience: `${candBasePath}/v1/experience`,
    postExperience: `${candBasePath}/v1/experience`,
    certificate: `${candBasePath}/v1/certificate/`,
    deleteCertificate: `${candBasePath}/v1/certificate`,
    skills: `${masterBasePath}/v1/skills`,
    getCourseData: `${masterBasePath}/v1/course`,
    getSpecializationData: `${masterBasePath}/v1/specialization`,
    getResume: `${candBasePath}/v1/resume`,
    getCategory: `${masterBasePath}/v1/categories`,
    getLocation: `${masterBasePath}/v1/locations`,
    checkMobile: `${candBasePath}/v1/checkmobile`,
    //checkMobile: `${environment.baseUrl}/candidate/v1/checkmobile`,
    checkEmail: `${candBasePath}/v1/checkemail`,
    getLanguage: `${masterBasePath}/v1/languages`,
    CertificationList: `${masterBasePath}/v1/certificates`,
    profileDataCheck: `${candBasePath}/v1/datacheck`,
    uploadDp: `${candBasePath}/v1/profileimage`,
    currency: `${masterBasePath}/v1/currencies/`,
    perecentage: `${candBasePath}/v1/completion`,
    getEducationData: `${masterBasePath}/v1/educations/`,
    clickCount: `${environment.baseUrl}/v1/candidate/impression/array`,
    InstituteSearch: `${masterBasePath}/v1/institutions`,
    UniversitySearch: `${masterBasePath}/v1/university`,
    CandidateViewCount: `${jobsBasePath}/v1/candidate/impression/array`,
    clickCount2: `${jobsBasePath}/v1/guest/impression`,
    emailotp: `${candBasePath}/v1/checkmobile`,
    OrganizationCandidateSearch: `${hrBasePath}/v1/candidate/search`,
    resetpassword: `${candAuthBasePath}/v1/userAuth/resetpassword`,
    getpasstoken: `${candAuthBasePath}/v1/userAuth/getpasstoken`,
    jobRecommended: `${jobsBasePath}/v1/candidate/list/`,
    jobIntersted: `${v1ApiBasePath}/candidate/jobs/recommended/ads`,
    deleteAccount: `${candBasePath}/v1/delete`,
  },

  job = {
    industries: `${masterBasePath}/v1/categories`,
    currencies: `${masterBasePath}/v1/currencies`,
    accomdation: `${masterBasePath}/v1/amenities`,
    qualification: `${masterBasePath}/v1/educations`,
    listJob: `${jobsBasePath}/v1/candidate/search`,
    countries: `${masterBasePath}/v1/countries`,
    countryLocations: `${masterBasePath}/v1/locations/country`,
    postJob: `${jobsBasePath}/v2/vendor/addjob`,
    postV3Job: `${jobsBasePath}/v3/vendor/addJob`,
    listJobId: `${jobsBasePath}/v1/guest/jobs`,
    listJob3: `${jobsBasePath}/v1/guest/search`,
    jobByVendor: `${jobsBasePath}/v1/vendor/myjobs`,
    companyJob: `${jobsBasePath}/v1/vendor/myjobs`,
    //applyJob: `${jobsAtsBasePath}/v1/candidate/apply`,
    applyJob: `${devAtsBasePath}/v1/candidate/apply`,
    jobDetails: `${jobsBasePath}/v1/guest/jobs/`,
    applyJobdetails: `${jobsAtsBasePath}/v1/candidate/applied`,
    postAds: `${jobsBasePath}/v2/vendor/addjob`,
    updateJob: `${jobsBasePath}/v1/vendor/update`,
    hiringStatus: `${jobsAtsBasePath}/v1/vendor/jobs/hiring/`,
    candidateReport: `${jobsBasePath}/v1/candidate/report`,
    tags: `${candBasePath}/v1/favcards`,
    activeDefaults: `${subsBasePath}/v1/vendor/payment/default`,
    priceCalculation: `${subsBasePath}/v1/vendor/payment/pricecalculation`,
    tagCandidates: `${candBasePath}/v1/favourites/array`,
    closeReason: `${jobsBasePath}/v1/vendor/closereasons`,
    reasonsCheckList: `${jobsBasePath}/v1/vendor/closereasons/`,
    categorySkills: `${masterBasePath}/v1/skills/skillsbycat/`,
    topCompanies: `${jobsBasePath}/v1/vendor/topcompanies`,
    uploadReason: `${jobsBasePath}/v1/vendor/close`,
    changeopenstatus: `${jobsBasePath}/v1/vendor/changeopenstatus`,
    review: `${hrBasePath}/v1/candidate/review`,
    reviewAnalytics: `${hrBasePath}/v1/vendor/`,
    atsByJob: `${jobsAtsBasePath}/v1/vendor/ats/steps/`,
    //https://msdev.hirewalks.com/jobATS/v1/vendor/ats/65fd58dcd20243efcd83aafc
    //https://msdev.hirewalks.com/jobs-api/v1/vendor/delete/45555
    jobUpdates: `${jobsBasePath}/v1/vendor/delete/`,
    campaignJobs: `${jobsBasePath}/v1/vendor/analysis/ads`,
    tagsExcel: `${candBasePath}/v1/favcards/exceldownload`,
    apiKeySetupStatus: `${hrBasePath}/v1/vendorUser/apiKey/setup/status`,



    categories: `${masterBasePath}/v1/categories`,
    //listJob2: `${v2ApiBasePath}/vendor/jobs/search`,
    recentJob: `${v1ApiBasePath}/candidate/jobs/recent`,
    latestJobs: `${v1ApiBasePath}/details/latestjobs`,
    supplierJob: `${v1ApiBasePath}/supplier/appliedjobs`,
    addReview: `${v1ApiBasePath}/vendor/addreview`,
    document: `${v1ApiBasePath}/supplier/download`,
    report: `${v1ApiBasePath}/vendor/report`,
    deleteApplicant: `${v1ApiBasePath}/supplier/application`,
    topSuppliers: `${v1ApiBasePath}/details/topsuppliers`,
  },

  chat = {
    chatGroup: `${chatBasePath}/v1/group`,
    chatEntry: `${chatBasePath}/v1/entry`,
    deleteChat: `${chatBasePath}/v1/entry`,
    chatCount: `${chatBasePath}/v1/unread`,
    fileUpload: `${chatBasePath}/v1/upload`,
    deleteChatGroup: `${chatBasePath}/v1/chatgroup/`,
    chatList: `${chatBasePath}/v2/grouplists`,
    getFileURL: `${masterBasePath}/v1/file/url/vendor`,
    favChatGroup: `${chatBasePath}/v1/favorite/`,
    blockUnblock: `${chatBasePath}/v1/blockstatus`,
    checkFavChatGroup: `${chatBasePath}/v1/favorite/check`,
    getChat: `${v1ApiBasePath}/chats`,

  },

  vendor = {
    candidateApplied: `${jobsAtsBasePath}/v1/vendor`,
    candidateAppliedSort: `${environment.baseUrl}/v1/vendor/leaderboard/`,
    scheduleInterviewApi: `${jobsAtsBasePath}/v1/interview/schedule_interview`,
    scheduleInterviewV2Api: `${jobsAtsBasePath}/v1/vendor/ats/interviews`,
    //scheduleInterviewV2Api: `${devAtsBasePath}/v1/vendor/ats/interviews`,
    allSkills: `${masterBasePath}/v1/skills`,
    adsPayment: `${subsBasePath}/v1/vendor/payment`,
    candidateDbSearchV2: `${profileBasePath}/v2/vendor`,
    candidateDbSearchV3: `${profileBasePath}/v3/vendor/1`,
    candidateDbSearchMoreV2: `${profileBasePath}/v2/vendor/extradata`,
    candidateDbSearchMoreV3: `${profileBasePath}/v3/vendor/2`,
    coinsLedgerList: `${subsBasePath}/v1/vendor/ledger-list`,
    coinConfig: `${subsBasePath}/v1/vendor/coin-config`,
    walletToCoins: `${subsBasePath}/v1/vendor/convert-coins`,
    coinsAdboost: `${subsBasePath}/v1/vendor/ad-boost`,
    updateBlockedVendor: `${hrBasePath}/v1/vendor/update-blocked-vendor`,
    blockedVendorUser: `${hrBasePath}/v1/vendoruser/blocked-vendoruser`,
    reclaimStatus: `${subsBasePath}/v1/vendor/reclaim_status`,
    reclaimCoins: `${subsBasePath}/v1/vendor/reclaim-coins`,
    adsPause: `${subsBasePath}/v1/vendor/ads/pause `,  //https://msdev.hirewalks.com//jobs//v1/vendor//changeopenstatus/
    removeAdsStop: `${environment.baseUrl}/v1/vendor/ads/remove/`,
    verifyPayment: `${subsBasePath}/v1/vendor/payment/successcheck`,
    paymentCalculation: `${subsBasePath}/v1/vendor/payment/calculation`,
    shortlistFindCandidate: `${jobsAtsBasePath}/v1/vendor/findshortlist/`,
    //candidateDetails: `${candBasePath}/v1/vendor/`,
    candidateDetails: `${profileBasePath}/v1/vendor/candidate/`,
    shortlistCandidate: `${jobsAtsBasePath}/v1/vendor/shortlist/`,
    addQuickNotes: `${jobsAtsBasePath}/v1/vendor/quicknote/`,
    jobtitles: `${jobsBasePath}/v1/jobTemplates/`,
    jobGoalApi: `${jobsBasePath}/v1/vendor/jobgoals/`,
    v2JobGoalApi: `${jobsAtsBasePath}/v1/vendor/ats/goaltitles/`,
    interviewHistory: `${jobsAtsBasePath}/v1/interview/`,
    v2InterviewHistory: `${jobsAtsBasePath}/v1/vendor/ats/interviews/`,
    candidateGoalApi: `${jobsAtsBasePath}/v1/vendor/appgoals/`,
    offerList: `${offersBasePath}/v1/vendor/list`,
    //https://msdev.hirewalks.com/offers/v1/vendor/save-offer
    saveOffer: `${offersBasePath}/v1/vendor/save-offer`,
    updateOffer: `${offersBasePath}/v1/vendor/`,
    offerHistory: `${offersBasePath}/v1/vendor/`,
    deleteOffer: `${offersBasePath}/v1/vendor/delete/`,
    offersActive: `${offersBasePath}/v1/vendor/offers-active`,
    offersInActive: `${offersBasePath}/v1/vendor/offers-inactive`,
    multipleOfferCount: `${offersBasePath}/v1/vendor/multiple-offer-count`,
    getOfferDetail: `${offersBasePath}/v1/vendor/get-offer/`,
    vendorNotification: `${vendorNotifyBasePath}/v1/notification`,
    vendorNewNotification: `${vendorNotifyBasePath}/v2/notification`,
    calculateNotification: `${vendorNotifyBasePath}/v1/notification/newapps`,
    promocount: `${subsBasePath}/v1/vendor/ads/promocount`,
    //https://msdev.hirewalks.com/subscription/v1/vendor/ads/promocount/1
    changeHiringStatusApi: `${jobsAtsBasePath}/v1/vendor/jobs/hiring/`,
    findforjobCandidates2: `${candBasePath}/v1/vendor/findforjob`,
    jobpostcount: `${jobsBasePath}/v1/vendor/jobcount`,
    interviewcount: `${jobsAtsBasePath}/v1/interview/interviewcount`,
    viewMobileNumber: `${profileBasePath}/v1/vendor/mobile/`,
    getResumeApi: `${profileBasePath}/v2/vendor/resumes/`,
    analysisCount: `${jobsBasePath}/v1/vendor/analysis/count/`,
    hiredcount: `${jobsBasePath}/v1/vendor/selectcount`,
    goalsLeaderboard: `${jobsAtsBasePath}/v1/vendor/ats/leaderboard`,
    deviceCount: `${jobsBasePath}/v1/vendor/analysis/clickdevice/`,
    appsGraph: `${jobsBasePath}/v1/vendor/analysis/clickgraph/`,
    paymentViewsApi: `${subsBasePath}/v1/vendor/payment/view`,
    candidateAppliedAts: `${jobsAtsBasePath}/v1/vendor/ats/apps`,
    CandidateGoals: `${jobsAtsBasePath}/v1/vendor/ats/goals`,
    getLeaderBoardData: `${jobsAtsBasePath}/v1/vendor/ats/leaderboard`,
    getV2LeaderBoardData: `${jobsAtsBasePath}/v2/vendor/ats/leaderboard`,
    getV3LeaderBoardData: `${jobsAtsBasePath}/v3/vendor/ats/leader_board`,
    candidateDbSearchV4: `${profileBasePath}/v4/vendor`,
    evaluationResult: `${jobsAtsBasePath}/v1/interview/evaluation-result`,
    deleteCandidateTag: `${candBasePath}/v1/favourites/`,



    candidateList: `${v1ApiBasePath}/vendor/candidates`,
    adsStop: `${v1ApiBasePath}/vendor/ads/remove`,
    accountBalance: `${v1ApiBasePath}/vendor/payment/balance`,
    findforjobCandidates: `${v1ApiBasePath}/vendor/candidates/findforjob`,
    candidateDbSearch: `${v1ApiBasePath}/vendor/candidatesearch`,

    recharge: `${v1ApiBasePath}/vendor/payment/recharge`,
    RecruiterTransactions: `${v1ApiBasePath}/vendor/payment`,
    subscriptionPromote: `${v1ApiBasePath}/vendor/subscriptions/promote`,

    addCareerSetup: `${hrBasePath}/v1/vendorUser/apiKey/`,
    getCareerData: `${hrBasePath}/v1/vendorUser/apiKey/`,
    getInviteRoleData : `${vendorAuthBasePath}/v1/vendor/user-management/role`,
    getInvitedUsersList: `${vendorAuthBasePath}/v1/vendor/user-management/tenant-user`,
    sendInvitation: `${vendorAuthBasePath}/v1/vendor/user-management/tenant-user`,
    editInvitedUser: `${vendorAuthBasePath}/v1/vendor/user-management/tenant-user/`,
    deleteInvitedUser: `${vendorAuthBasePath}/v1/vendor/user-management/tenant-user/`,

  },

  candidate = {
    //appliedJobList: `${v1ApiBasePath}/candidate/jobs/applied`,
    appliedJobList: `${jobsAtsBasePath}/v1/candidate/applied`,
    candidateMobileSignUp: `${candAuthBasePath}/v2/userAuth/mobilesignup`,
    accountStatus: `${candBasePath}/v1/update`,
    interviewList: `${jobsAtsBasePath}/v1/candidateInterview/`,
    interviewAction: `${jobsAtsBasePath}/v1/candidateInterview/reschedule`,
    candidateEmail: `${profileBasePath}/v1/vendor/email`,
    candidateMobile: `${profileBasePath}/v1/vendor/mobile`,
    candidateGoogleSignUp: `${candAuthBasePath}/v2/userAuth/googlesignup`,
    candidateResume: `${candBasePath}/v1/resume/generatedresume/`,
    call: `${jobsAtsBasePath}/v1/interview/immediatecall/`,
    extractresume: `${candBasePath}/v1/resume/extractdata`,
    updateAts: `${jobsAtsBasePath}/v1/vendor/ats/apps/`,
    candidateTimeline: `${jobsAtsBasePath}/v1/vendor/ats/timelines/`,
    //https://msdev.hirewalks.com/jobATS/v1/vendor/ats/timelines/:app_id
    favTaggedCandGroup: `${candBasePath}/v1/favourites/`,
    v2FindShortList: `${jobsAtsBasePath}/v2/vendor/findshortlist/`,
    extractData: `${candBasePath}/v1/resume/extractdata`,
    newApply: `${devAtsBasePath}/v1/vendor/new/apply/`,
    vendorAddedCandidateDetails: `${candBasePath}/v1/vendor/vendorAddedCandidate/profile/`,



    topCompanies: `${hrBasePath}/v1/candidate/top-companies`,
    //https://dev.hirewalks.com/api/v1/vendor/atssteps/timelines/:app_id
  },

  ats_steps = {
    changelevel: `${jobsAtsBasePath}/v1/vendor/ats/changelevel/`,
    newStep: `${jobsAtsBasePath}/v1/vendor/ats/`,
    deleteStep: `${jobsAtsBasePath}/v1/vendor/ats/`,
    editStep: `${jobsAtsBasePath}/v1/vendor/ats/`,
    applicationsCount: `${jobsAtsBasePath}/v1/vendor/ats/applications-count/`,
    devApplicationsCount: `${jobsAtsBasePath}/v1/vendor/ats/applications-count/`,
    /* / v1 / vendor / ats / applications - count / 663b353a66dad6014e31f7d1 */
    //https://msdev.hirewalks.com/dev-ats/v1/vendor/quickNote/:app_id?ats_step_id=
    quickNote: `${jobsAtsBasePath}/v1/vendor/quickNote/`,
    appQuickNotes: `${jobsAtsBasePath}/v1/vendor/appQuickNotesEvaluation/`,
  },

  application = {
    getResume: `${profileBasePath}/v1/vendor/resumes/`,
    getAllApplication: `${jobsAtsBasePath}/v1/vendor/ats/apps`,
    getInterviews: `${jobsAtsBasePath}/v2/vendor/ats/interviews/`,
    evaluationScore: `${jobsAtsBasePath}/v1/vendor/ats/evaluationScore/`,
    candidateReport: `${jobsAtsBasePath}/v1/vendors/candidate-report`,
    //https://msdev.hirewalks.com/dev-ats/v2/vendor/ats/interviews/:app_id
    //https://msdev.hirewalks.com/dev-ats/v1/vendor/ats/evaluationScore/:interview_id
  },

  walkins = {
    walkInJobs: `${jobsBasePath}/v1/candidate-walk-in/jobs`,
    walkinsRegister: `${candAuthBasePath}/v1/candidate-walk-in/register`,
  },
   
  contract = {
    contractJobRequest: `${jobsBasePath}/v1/vendor/contract-job-request`,
  },

  hr_talent = {
    externalPaymentReq: `${subsBasePath}/v1/vendor/payment/external-payment-request`,
  }

