<div class="insufficient-container">
    <!-- title -->
    <div fxLayout="row" fxLayoutAlign="space-between start" class="title-box">
        <div></div>

        <div class="title-txt" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.3rem">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <g clip-path="url(#clip0_8515_9689)">
                        <path
                            d="M15.0813 9.74098C16.0362 9.74098 16.8109 8.96619 16.8109 8.01129C16.8109 7.05522 16.0362 6.28271 15.0813 6.28271C14.1263 6.28271 13.3516 7.05527 13.3516 8.01129C13.3516 8.96619 14.1263 9.74098 15.0813 9.74098Z"
                            fill="#DBA533" />
                        <path
                            d="M16.2562 12.0472V11.4413V10.894H12.7969V12.0472H13.95V21.8487H12.7969V23.0019H17.4094V21.8487H16.2562V12.0472Z"
                            fill="#DBA533" />
                        <path
                            d="M15.0078 0.56543C7.04744 0.56543 0.59375 7.01912 0.59375 14.9795C0.59375 22.941 7.04744 29.3936 15.0078 29.3936C22.9682 29.3936 29.4219 22.941 29.4219 14.9795C29.4219 7.01912 22.9682 0.56543 15.0078 0.56543ZM15.0078 28.2404C7.69549 28.2404 1.74688 22.2912 1.74688 14.9795C1.74688 7.66775 7.69555 1.71855 15.0078 1.71855C22.3201 1.71855 28.2688 7.66775 28.2688 14.9795C28.2688 22.2912 22.3201 28.2404 15.0078 28.2404Z"
                            fill="#DBA533" />
                    </g>
                    <defs>
                        <clipPath id="clip0_8515_9689">
                            <rect width="30" height="30" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div class="title">
                Oops, it seems you don't have enough coins!
            </div>
        </div>

        <div class="cursor close-btn" mat-dialog-close>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M7 7L25 25" stroke="#696767" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7 25L25 7" stroke="#696767" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    </div>

    <div class="content-box" fxLayout="column" fxLayoutAlign="center center">
        <div class="primary-txt">
            To unlock these awesome features, consider purchasing some coins
        </div>

        <div class="sec-txt" fxLayoutGap="1.875rem">
            <div>Make your recruitment process more efficient, cost-effective, and hassle-free,</div>
            <div>saving you valuable time and effort.</div>
        </div>

        <button mat-stroked-button class="primary-stroked-button cancel-button" style="margin-top: 1.6rem;" *ngIf="isEligible" (click)="claimFreeCoins()">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.2rem">
                <div class="amount-txt">
                    Claim the Free Coins
                </div>
                <div>
                    <img src="assets/gold-coin.svg" style="width: 1rem; height: 1rem;">
                </div>
                <div class="amount-txt amount-price">
                    50
                </div>
            </div>
        </button>

        <button mat-stroked-button class="continue-button" fxLayout="row" (click)="pricingPage()" *ngIf="isEligible" (click)="pricingPage()">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5rem">
                <div>Buy More Coins</div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                            d="M19.7444 12.653H21.1506V20.1488C21.1499 20.7535 20.9093 21.3332 20.4817 21.7608C20.0541 22.1884 19.4744 22.4289 18.8697 22.4297H3.87859C3.27388 22.4289 2.69415 22.1884 2.26655 21.7608C1.83895 21.3332 1.5984 20.7535 1.59766 20.1488V5.15625C1.59877 4.55178 1.83949 3.97241 2.26705 3.54511C2.6946 3.11782 3.27412 2.87746 3.87859 2.87672H11.3744V4.28297H3.87859C3.64693 4.28322 3.42481 4.37526 3.26087 4.53893C3.09692 4.70261 3.00453 4.92459 3.00391 5.15625V20.1488C3.00415 20.3807 3.09639 20.603 3.26037 20.767C3.42435 20.931 3.64669 21.0232 3.87859 21.0234H18.8697C19.1016 21.0232 19.3239 20.931 19.4879 20.767C19.6519 20.603 19.7441 20.3807 19.7444 20.1488V12.653ZM16.51 1.57031V2.97656H19.9975L11.6941 11.2819L12.6883 12.2761L20.9936 3.97078V7.45828H22.3998V1.57031H16.51Z"
                            fill="white" />
                    </svg>
                </div>
            </div>
        </button>
        
        <button mat-stroked-button class="primary-stroked-button cancel-button" style="margin-top: 1.6rem;" *ngIf="!isEligible" (click)="contactusRedirect()">
            <div class="amount-txt">
                Contact Sales for Best Offer’s
            </div>
        </button>

        <div class="countdown-number" *ngIf="!isEligible">
            {{countdown.toString()?.padStart(2, '0') || 00}}
        </div>
    </div>

    <div class="bg-img">
        <img src="assets/PLAN-CHARACTERS.svg" class="plan-character-img">
    </div>
</div>