import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, filter, take, switchMap, retry } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import * as urls from '../../common/end-points/urls';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  deviceInfo: any;
  deviceInfoAll: any;
  ip: any;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private notification: NotificationService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService
  ) {
    this.ip = localStorage.getItem('ip');
    this.getDeviceInfo();
  }
  getDeviceInfo() {
    this.deviceInfo = {
      app: '',
      os: '',
      device: '',
      device_type: '',
      ip_address: this.ip,
      browser_version: '',
      os_version: '',
      browser_name: this.detectBrowserName()
    };
    this.deviceInfoAll = this.deviceService.getDeviceInfo();
    this.deviceInfo.os = this.deviceInfoAll.os;
    this.deviceInfo.device_type = this.deviceInfoAll.deviceType;
    this.deviceInfo.device = this.deviceInfoAll.device;
    this.deviceInfo.app = 'web';
    this.deviceInfo.ip_address = localStorage.getItem('ip');
    this.deviceInfo.browser_version = this.deviceInfoAll.browser_version;
    this.deviceInfo.os_version = this.deviceInfoAll.os_version;
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(this.addAuthenticationToken(request)).pipe(
      catchError((error) => {
        if (
          request.url.includes(urls.auth.refreshTokens) ||
          request.url.includes(urls.auth.refreshTokens2) ||
          request.url.includes(urls.auth.login) ||
          request.url.includes(urls.auth.candidatelogin) ||
          request.url.includes(urls.auth.mobilelogin)
        ) {
          this.refreshTokenInProgress = false;
          if (request.url.includes(urls.auth.refreshTokens)) {
            this.notification.showError(
              'Session Expired, Please Login',
              'Error'
            );
            this.authService.logOut();
            return throwError(error);
          }
          return throwError(error);
        }
        if (error.status !== 401) {
          return throwError(error);
        }
        // if (error.status == 401) {
        //   // this.refreshTokenInProgress=true
        //   // this.notification.showError('Session Expired, Please Login', 'Error')
        //   // this.authService.logOut();
        // }
        if (this.refreshTokenInProgress) {
          //     // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          //     // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject.pipe(
            filter((result) => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(request))),
            catchError((err) => {
              this.refreshTokenInProgress = false;
              this.notification.showError(
                'Session Expired, Please Login',
                'Error'
              );
              this.authService.logOut();
              return throwError(error);
            })
          );
        } else {
          this.refreshTokenInProgress = true;
          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          this.refreshTokenSubject.next(null);
          let token = { refreshToken: localStorage.getItem('refreshToken') };
          if (this.authService.userType) {
            return this.authService.updateRefreshToken(token).pipe(
              switchMap((authUser: any) => {
                //sometimes res name refreshToken or authToken thats why authUser.refreshToken || authUser.authToken
                this.authService.setAuthToken(authUser.refreshToken || authUser.authToken);
                this.authService.setAuthToken(authUser.authToken);
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(authUser.refreshToken || authUser.authToken);
                this.refreshTokenSubject.next(authUser.authToken);
                return next.handle(this.addAuthenticationToken(request));
              }),
              catchError((err) => {
                this.authService.logOut();
                this.refreshTokenInProgress = false;
                return throwError(err);
              })
            );
          }

          if (this.authService.candidateId) {
            return this.authService.updateCandidateRefreshToken(token).pipe(
              switchMap((authUser: any) => {
                this.authService.setAuthToken(authUser.refreshToken || authUser.authToken);
                this.refreshTokenInProgress = false;
                this.refreshTokenSubject.next(authUser.refreshToken || authUser.authToken);
                return next.handle(this.addAuthenticationToken(request));
              }),
              catchError((err) => {
                this.authService.logOut();
                this.refreshTokenInProgress = false;
                return throwError(err);
              })
            );
          }
        }
        return throwError(error);
      })
    );
  }
  private addAuthenticationToken(request: any): any {
    if (
      request.url.includes(urls.auth.refreshTokens) ||
      request.url.includes(urls.auth.refreshTokens2) ||
      request.url.includes(urls.auth.login) ||
      request.url.includes(urls.auth.candidatelogin) ||
      request.url.includes(urls.auth.mobilelogin)
    ) {
      let newHeaders = request.headers;
      newHeaders = newHeaders.append('device', JSON.stringify(this.deviceInfo));
      return request.clone({ headers: newHeaders });//need device details in login api
    } else {
      const authToken: string = localStorage.getItem('token') || ''; //this.authService.getAuthToken();
      let newHeaders = request.headers;
      if (authToken && !request.url.includes(urls.auth.resetWithOtp)) {
        // If we have a token, we append it to our new headers
        newHeaders = newHeaders.append('Authorization', `${authToken}`);
      }
      newHeaders = newHeaders.append('device', JSON.stringify(this.deviceInfo));

      // Finally we have to clone our request with our new headers
      // This is required because HttpRequests are immutable
      return request.clone({ headers: newHeaders });
    }
  }
}
