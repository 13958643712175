import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private toastr: ToastrService
  ) { }

  // function to show toastr success message
  showSuccess(message: string, title?: string) {
    this.toastr.success(this.capitalizeFirstLetter(message), title ? title : 'Success');
  }

  // function to show toastr error message
  showError(message: string, title?: string):any {
    this.toastr.error(this.capitalizeFirstLetter(message), title ? title : 'Error');
  }

  // function to show toastr information message
  showInfo(message: string, title?: string) {
    this.toastr.info(this.capitalizeFirstLetter(message), title ? title : 'Info');
  }

  // function to show toastr warning message
  showWarning(message: string, title?: string) {
    this.toastr.warning(this.capitalizeFirstLetter(message), title ? title : 'Warning');
  }

  capitalizeFirstLetter(str: string): string {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }
}
