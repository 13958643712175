import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import * as urls from 'src/app/common/end-points/urls';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { HomeService } from 'src/app/services/home/home.service';

@Component({
  selector: 'app-insufficient-popup',
  templateUrl: './insufficient-popup.component.html',
  styleUrls: ['./insufficient-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InsufficientPopupComponent implements OnInit {
  countdown: number = 5;
  isEligible = false;
  pageName: any;
  private interval: any;

  constructor(
    public router: Router,
    private http: HttpService,
    public dialogRef: MatDialogRef<any>,
    private notification: NotificationService,
    public homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.reclaimStatus();
    /* this.startCountDown(); */
    // Use setInterval to update the countdown every second
  }

  reclaimStatus() {
    this.http.getRequest(urls.vendor.reclaimStatus).subscribe((res: any) => {
      if (res.msg == 'eligible for reclaim ') {
        this.isEligible = true;
      } else {
        this.startCountDown();
      }
    }, err => {
      this.startCountDown();
    })
  }

  pricingPage() {
    this.router.navigate(['recruiter/new-pricing']);
    this.dialogRef.close();
  }

  claimFreeCoins() {
    this.http.postRequest(urls.vendor.reclaimCoins, '').subscribe((res: any) => {
      console.log(res);
      this.notification.showSuccess(res, '');
      this.dialogRef.close();
      this.homeService.ledgerBalance();
    })
  }

  startCountDown() {
    this.interval = setInterval(() => {
      this.countdown--;
      if (this.countdown < 0) {
        this.countdown = 0;
        if (this.pageName !== 'contact-us') {
          this.router.navigate(['recruiter/new-pricing']);
        }
        this.dialogRef.close();
      }
    }, 1000);
  }

  contactusRedirect() {
    this.router.navigate(['/info/contact-us']);
    var cururl = this.router.url;
    var lastpath = cururl.lastIndexOf('/');
    this.pageName = cururl.substring(lastpath + 1);
    console.log(this.router.url);
    this.dialogRef.close();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
