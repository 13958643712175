import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as urls from 'src/app/common/end-points/urls'
import { AuthService } from '../auth/auth.service';
import { map } from 'rxjs/operators';
import { ProfileService } from '../profile/profile.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  notificationlength: any;
  notifyData: any;
  total: any;
  pageIndex: any = 0;
  pageSize: any = 20;
  userTypeLocal: any;
  noNotification!: boolean;
  invokeEvent: Subject<any> = new Subject();
  closeEvent: Subject<any> = new Subject();
  isShrunk = true;
  /* coinBalance = 0; */
  coinBalance: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  shimmer = false;
  isLoginpage = false;
  balLoader = false;
  candIdFromNotification: any = '';
  isInAppliedCandPage = false;


  constructor(
    private http: HttpClient,
    public auth: AuthService,
    public profile: ProfileService) { }

  //api call to get locations
  locations(): Observable<any> {
    return this.http.get<any>(urls.auth.locations)
  }
  logoCompanies() {
    return this.http.get<any>(urls.auth.logos)
  }
  contact(body: any) {
    return this.http.post<any>(`${urls.auth.v2Contact}`, body)

  }

  ledgerBalance() {
    this.balLoader = true;
    this.profile.ledgerBalance().subscribe(res => {
      this.updateCoinBalance(res.totalBalance);
      this.balLoader = false;
    });
  }

  // Update the coinBalance value
  updateCoinBalance(newBalance: number) {
    this.coinBalance.next(newBalance);
  }

  /* public ledgerBalance() {
    console.log('Hi')
    this.http.get<any>(urls.auth.ledgerBalance)
    this.http.get(urls.auth.ledgerBalance).pipe(
      map((res: any) => {
        console.log(res);
        this.coinBalance = res.totalBalance;
      })
    );
  } */

  subscriptionPlans() {
    return this.http.get<any>(urls.auth.subscriptionPlans)
  }

  coinsPlans(annualPlan: any) {
    let qb = `annualPlan=${annualPlan}`
    return this.http.get<any>(`${urls.auth.coinsPlan}?${qb}`)
  }

  PlanActive() {
    return this.http.get<any>(urls.auth.planActive)
  }
  PlanActive2() {
    return this.http.get<any>(urls.auth.planActive2)
  }

  TrialPlan() {
    return this.http.get<any>(urls.auth.trialPlan)
  }
  getCompanies() {
    return this.http.get<any>(urls.auth.companyList)
  }
  vendorNotification() {
    let qb = '';
    let limit = this.pageSize;
    let page = this.pageIndex + 1;
    qb = qb + `limit=${limit}&skip=${(page - 1) * this.pageSize}&`;

    return this.http.get<any>(`${urls.vendor.vendorNewNotification}?${qb}`);
    // return this.http.get<any>(`${urls.job.jobByVendor}?${qb}`);
  }

  readStatus(body: any) {
    return this.http.post<any>(`${urls.vendor.vendorNotification}`, body)
  }

  calculateNotification() {
    return this.http.post<any>(`${urls.vendor.calculateNotification}`, {})
  }

  vendorNotifications() {
    if (this.auth.userType == 3 || this.auth.userType == 1) {
      this.vendorNotification().subscribe((res: any) => {
        this.total = res.total;

        if (res.total > 0) {
          this.noNotification = false
          this.notifyData = res.data
          this.notificationlength = 0;

          // for notification unread length
          this.notifyData.filter((ele: any) => {

            if (ele.read_status == false) {
              this.notificationlength = this.notificationlength + 1;
            }

          })
          //if all notifications are read
          if (this.notificationlength == 0) {
            this.notificationlength = null
          }


        }

        else {
          this.notificationlength = null

          this.noNotification = true


        }

      })

    }

  }


  userTypecheck() {

    if (isNaN(this.auth.userType) || this.auth.userType == null) {

      this.userTypeLocal = null;

    }
    else {

      this.userTypeLocal = 0
    }

  }

  setMetaData(val: any) {
    this.invokeEvent.next(val);
  }

  recsideBar(val: any) {
    this.closeEvent.next(val);
  }







}
